import axios from 'axios'
import store from '../store'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '../store/mutation-types'

const baseURL = process.env.VUE_APP_DEV == 1 ? 'http://127.0.0.1:3000' : `${new URL(location.href).origin}` ;

const request = axios.create({
  // API
  baseURL: `${baseURL}/api/`,
  timeout: 6000 // Timeout
})

// Error
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = localStorage.getItem(ACCESS_TOKEN)
    if (error.response.status === 403) {
        // TODO
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      // TODO
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  // Bearer token
  if (token) {
    config.headers['authorization'] = `${token}`
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
