import request from '../utils/requests' 

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: ''
 * }
 */
export function login (parameter) {
    return request({
      url: '/user/auth/login',
      method: 'post',
      data: parameter
    })
}


export function logout () {
    return request({
      url: '/user/auth/logout',
      method: 'post'
    })
}

export function reset (parameter) {
  return request({
    url: '/user/auth/reset',
    method: 'post',
    data: parameter
  })
}

export function newPassword (parameter) {
  return request({
    url: '/user/auth/password-reset',
    method: 'post',
    data: parameter
  })
}

export function updatePassword (parameter) {
  return request({
    url: '/user/auth/update-password',
    method: 'post',
    data: parameter
  })
}

export function newAdmin (parameter) {
  return request({
    url: '/user/auth/register-admin',
    method: 'post',
    data: parameter
  })
}