import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

import { login, logout, updatePassword } from '../api/user'
import { getCourses, getCourseSubjects, getQuizes, getAssignments, getAttendance, getAssignmentStat, getQuizStat, getStudentStat, getMoocStat } from '../api/course'
import { ACCESS_TOKEN } from './mutation-types'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: null,
    user: {},
    courses: [],
    courseSubjects: [],
    quizes: [],
    assignments: [],
    studentStat: [],
    attendance: null,
    assignmentStat: null,
    quizStat: null,
    moocStat: null,
    statSelectedCourseSubject: 0,
  },
  mutations: {
    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken;
    },
    user: (state, user) => {
      state.user = user;
    },
    courses: (state, courses) => {
      state.courses = courses;
    },
    courseSubjects: (state, courseSubjects) => {
      state.courseSubjects = courseSubjects;
    },
    quizes: (state, quizes) => {
      state.quizes = quizes;
    },
    assignments: (state, assignments) => {
      state.assignments = assignments;
    },
    attendance: (state, attendance) => {
      state.attendance = attendance;
    },
    assignmentStat: (state, assignmentStat) =>{
      state.assignmentStat = assignmentStat;
    },
    quizStat: (state, quizStat) =>{
      state.quizStat = quizStat;
    },
    moocStat: (state, moocStat) =>{
      state.moocStat = moocStat;
    },
    studentStat: (state, studentStat) => {
      state.studentStat = studentStat;
    }
  },
  actions: {
    doLogin({ commit, dispatch }, loginData) {
      login(loginData)
      .then(response => {
        localStorage.setItem(ACCESS_TOKEN, response.data.token);
        commit('updateAccessToken', response.data.token);
        commit('user',response.data);

        /**
         * Redirect to dashboard
         */
        router.push('/dashboard');
      })
      .catch(e => {
        if(e.code === 'ERR_BAD_REQUEST'){
          this._vm.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        commit('updateAccessToken', null);
        commit('user', null);
      })
    },
    doUpdatePassword({ commit, dispatch }, passwordData) {
      updatePassword(passwordData)
      .then(response => {
        commit('user',response.data);
        this._vm.$toast.open({
          message: 'Password successfully updated.',
          type: 'info',
        });
      })
      .catch(e => {
        if(e.code === 'ERR_BAD_REQUEST'){
          this._vm.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this._vm.$toast.open({
            message: 'Unable to update the password at this moment.',
            type: 'error',
          });
        }
      })
    },
    fetchAccessToken({ commit }) {
      commit('updateAccessToken', localStorage.getItem(ACCESS_TOKEN));
      localStorage.setItem(ACCESS_TOKEN, response.data.token);
    },
    doLogout({ commit}){
      logout()
      .then(response => {
        localStorage.setItem(ACCESS_TOKEN, null);
        router.push('/pages/login');
        commit('updateAccessToken', null);
        commit('user', {});
      })
      .catch(error => {
        commit('updateAccessToken', null);
        commit('user', {});
        localStorage.setItem(ACCESS_TOKEN, null);
      })
    },
    fetchCourses({ commit }) {
      getCourses()
      .then(response => {
        commit('courses', response.data);
      })
      .catch(error => {
        commit('updateAccessToken', null);
        localStorage.setItem(ACCESS_TOKEN, null);
      })
    }, 
    fetchCourseSubjects({ commit }) {
      getCourseSubjects()
      .then(response => {
        commit('courseSubjects', response.data);
      })
      .catch(error => {
        commit('updateAccessToken', null);
        localStorage.setItem(ACCESS_TOKEN, null);
      })
    },
    fetchQuizes({ commit }) {
      getQuizes()
      .then(response => {
        commit('quizes', response.data);
      })
      .catch(error => {
        commit('updateAccessToken', null);
        localStorage.setItem(ACCESS_TOKEN, null);
      })
    },
    fetchAssignments({ commit }) {
      getAssignments()
      .then(response => {
        commit('assignments', response.data);
      })
      .catch(error => {
        commit('updateAccessToken', null);
        localStorage.setItem(ACCESS_TOKEN, null);
      })
    },
    fetchAttendance({ commit }, data) {
      getAttendance(data)
      .then(response => {
        commit('attendance', response.data);
      })
      .catch(error => {
        commit('updateAccessToken', null);
        localStorage.setItem(ACCESS_TOKEN, null);
      })
    },
    fetchAssignmentStat({ commit }, data) {
      getAssignmentStat(data)
      .then(response => {
        commit('assignmentStat', response.data);
      })
      .catch(error => {
        commit('updateAccessToken', null);
        localStorage.setItem(ACCESS_TOKEN, null);
      })
    },
    fetchQuizStat({ commit }, data) {
      getQuizStat(data)
      .then(response => {
        commit('quizStat', response.data);
      })
      .catch(error => {
        commit('updateAccessToken', null);
        localStorage.setItem(ACCESS_TOKEN, null);
      })
    },
    fetchMoocStat({ commit }, data) {
      getMoocStat(data)
      .then(response => {
        commit('moocStat', response.data);
      })
      .catch(error => {
        commit('updateAccessToken', null);
        localStorage.setItem(ACCESS_TOKEN, null);
      })
    },
    fetchStudentStat({ commit }, params) {
      getStudentStat(params)
      .then(response => {
        commit('studentStat', response.data);
      })
      .catch(error => {
        commit('updateAccessToken', null);
        localStorage.setItem(ACCESS_TOKEN, null);
      })
    }
  },
  getters: {
    isLoggedIn: state => {
      return state.accessToken !== null;
    },
    isStaff: state => {
      return state.user.isStaff;
    },
    user: state => {
      return state.user;
    },
    getToken: state => {
      return state.accessToken;
    },
    getCourses: state => {
      return state.courses;
    },
    getCourseSubjects: state => {
      return state.courseSubjects;
    },
    getQuizes: state => {
      return state.quizes;
    },
    getAssignments: state => {
      return state.assignments;
    },
    getAttendance: state => {
      return state.attendance;
    },
    getAssignmentStat: state => {
      return state.assignmentStat;
    },
    getQuizStat: state => {
      return state.quizStat;
    },
    getMoocStat: state => {
      return state.moocStat;
    },
    getStudentStat: state => {
      return state.studentStat;
    }
  },
  modules: {},
  plugins: [createPersistedState()]
})
