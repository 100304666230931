<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          max-height="60px"
          max-width="60px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
        </v-slide-x-transition>
      </router-link>
    </div>

    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Account Settings"
        :to="{ name: 'pages-account-settings'}"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>

      <!-- Navigation Divider TEXT  -->
      <nav-menu-section-title  v-if="staff" title="COURSE"></nav-menu-section-title>
      <nav-menu-section-title  v-else title="COURSE"></nav-menu-section-title>

      <!-- Navigation Items [ COMMON] -->
      <nav-menu-link
        title="Course Schedule"
        :to="{ name: 'calendar' }"
        :icon="icons.mdiCalendar"
      ></nav-menu-link>

      <nav-menu-link
        v-if="staff"
        title="Student Performance"
        :to="{ name: 'student-table' }"
        :icon="icons.mdiSchool"
      >
      </nav-menu-link>

      <nav-menu-section-title  v-if="staff" title="UPLOAD"></nav-menu-section-title>

      <nav-menu-link
        v-if="staff"
        title="Upload Data"
        :to="{ name: 'uploads' }"
        :icon="icons.mdiUpload"
      >
      </nav-menu-link>

      <nav-menu-section-title  v-if="staff" title="CAPSTONE PROJECT"></nav-menu-section-title>

      <nav-menu-link
        v-if="staff"
        title="Captone Teams"
        :to="{ name: 'teams' }"
        :icon="icons.mdiAccountGroupOutline"
      >
      </nav-menu-link>

      <nav-menu-section-title  v-if="staff" title="FINAL PROJECT"></nav-menu-section-title>

      <nav-menu-link
        v-if="staff"
        title="Project Teams"
        :to="{ name: 'projects' }"
        :icon="icons.mdiAccountGroupOutline"
      >
      </nav-menu-link>

      <!-- Navigation Items [ STUDENT ] -->
      <nav-menu-section-title  v-if="!staff" title="CAPSTONE PROJECT"></nav-menu-section-title>

      <nav-menu-link
        v-if="!staff"
        title="Self & Peer Review"
        :to="{ name: 'teams-eval' }"
        :icon="icons.mdiAccountGroupOutline"
      >
      </nav-menu-link>

      <nav-menu-link
        v-if="!staff"
        title="My Capstone Scores"
        :to="{ name: 'my-capstone-scores' }"
        :icon="icons.mdiChartDonut"
      >
      </nav-menu-link>
      
      <nav-menu-section-title  v-if="!staff" title="FINAL PROJECT"></nav-menu-section-title>

      <nav-menu-link
        v-if="!staff"
        title="Project Schedule"
        :to="{ name: 'final-project-schedule' }"
        :icon="icons.mdiCalendar"
      >
      </nav-menu-link>

      <nav-menu-link
        v-if="!staff"
        title="Self & Peer Review"
        :to="{ name: 'final-project-eval' }"
        :icon="icons.mdiAccountGroupOutline"
      >
      </nav-menu-link>

      <nav-menu-link
        v-if="!staff"
        title="My Project Score"
        :to="{ name: 'my-project-scores' }"
        :icon="icons.mdiChartDonut"
      >
      </nav-menu-link>

    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import {
    mdiHomeOutline,
    mdiEyeOutline,
    mdiAccountGroupOutline,
    mdiChartDonut,
    mdiSchool,
    mdiFileOutline,
    mdiFormSelect,
    mdiAccountCogOutline,
    mdiUpload,
    mdiCalendar,
    mdiDeveloperBoard
  } from '@mdi/js'

  import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
  import NavMenuGroup from './components/NavMenuGroup.vue'
  import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      icons: {
        mdiHomeOutline,
        mdiEyeOutline,
        mdiAccountGroupOutline,
        mdiSchool,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiUpload,
        mdiCalendar,
        mdiChartDonut,
        mdiDeveloperBoard
      },
    }
  },
  computed: {
    staff(){
      return this.$store.getters.isStaff
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-title {
    font-size: 1.25rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
  }

  // ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
  .app-logo {
    transition: all 0.18s ease-in-out;
    .v-navigation-drawer--mini-variant & {
      transform: translateX(-4px);
    }
  }

  @include theme(app-navigation-menu) using ($material) {
    background-color: map-deep-get($material, 'background');
  }

  .app-navigation-menu {
    .v-list-item {
      &.vertical-nav-menu-link {
        ::v-deep .v-list-item__icon {
          .v-icon {
            transition: none !important;
          }
        }
      }
    }
  }
</style>
