import request from '../utils/requests' 

export function getCourses () {
    return request({
      url: '/course/routes/courses',
      method: 'post'
    })
}

export function getCourseSubjects () {
  return request({
    url: '/course/routes/courseSubjects',
    method: 'post'
  })
}

export function getQuizes () {
  return request({
    url: '/course/routes/quizes',
    method: 'post'
  })
}

export function getGroupCriterias () {
  return request({
    url: '/course/routes/groupCriterias',
    method: 'post'
  })
}

export function getAssignments () {
  return request({
    url: '/course/routes/assignments',
    method: 'post'
  })
}

export function getAttendance (data) {
  return request({
    url: '/stats/routes/batchAtendance',
    method: 'post',
    data: data
  })
}

export function getAssignmentStat (data) {
  return request({
    url: '/stats/routes/batchAssignment',
    method: 'post',
    data: data
  })
}

export function getStudentStat (params) {
  return request({
    url: '/stats/routes/studentStats',
    method: 'post',
    data: params
  })
}

export function getQuizStat (data) {
  return request({
    url: '/stats/routes/batchQuiz',
    method: 'post',
    data: data
  })
}

export function getMoocStat (data) {
  return request({
    url: '/stats/routes/batchMooc',
    method: 'post',
    data: data
  })
}

export function addStudentToCourse (data) {
  return request({
    method: 'post',
    url: '/student/routes/addStudent',
    data: data
  })
}

export function addMoocScore (data) {
  return request({
    method: 'post',
    url: '/student/routes/addMoocScore',
    data: data
  })
}

export function markAttendance (data) {
  return request({
    method: 'post',
    url: '/student/routes/addStudentAttendance',
    data: data
  })
}

export function markQuizScore (data) {
  return request({
    method: 'post',
    url: '/student/routes/addQuizScore',
    data: data
  })
}

export function markAssignmentScore (data) {
  return request({
    method: 'post',
    url: '/student/routes/addAssignmentScore',
    data: data
  })
}

export function fetchStudentTeams (data) {
  return request({
    method: 'post',
    url: '/course/routes/teamAndStudents',
    data: data
  })
}

export function fetchCapstoneParameter (data) {
  return request({
    method: 'post',
    url: '/course/routes/capstoneProjectParameter',
    data: data
  })
}

export function mapProjectTeamToCapstone(data) {
  return request({
    method: 'post',
    url: '/student/routes/mapProjectTeamToCapstone',
    data: data
  })
}

export function fetchTeamMembers(data) {
  return request({
    method: 'post',
    url: '/student/routes/fetchTeamMembers',
    data: data
  })
}

export function makeTeamLeader(data) {
  return request({
    method: 'post',
    url: '/student/routes/makeLeader',
    data: data
  })
}

export function fetchProjectTeamAndStudents(data) {
  return request({
    method: 'post',
    url: '/course/routes/fetchProjectTeamAndStudents',
    data: data
  })
}

export function fetchTeamMemberScoresAdmin(data) {
  return request({
    method: 'post',
    url: '/student/routes/fetchTeamMemberScoresAdmin',
    data: data
  })
}

export function fetchProjectTeamMemberScoresAdmin(data) {
  return request({
    method: 'post',
    url: '/student/routes/fetchProjectTeamMemberScoresAdmin',
    data: data
  })
}

export function fetchSelfAssessmentScoresAdmin(data) {
  return request({
    method: 'post',
    url: '/student/routes/fetchSelfAssessmentScoresAdmin',
    data: data
  })
}

export function fetchPeerAssessmentScoresAdmin(data) {
  return request({
    method: 'post',
    url: '/student/routes/fetchPeerAssessmentScoresAdmin',
    data: data
  })
}

export function fetchGroupAssignmentStudentScoresAdmin(data) {
  return request({
    method: 'post',
    url: '/student/routes/fetchGroupAssignmentStudentScoresAdmin',
    data: data
  })
}

export function fetchTeamMemberComments(data) {
  return request({
    method: 'post',
    url: '/student/routes/fetchTeamMemberComments',
    data: data
  })
}

export function fetchProjectTeamMemberComments(data) {
  return request({
    method: 'post',
    url: '/student/routes/fetchProjectTeamMemberComments',
    data: data
  })
}

export function addGroupAssignmentScore(data) {
  return request({
    method: 'post',
    url: '/student/routes/addGroupAssignmentScore',
    data: data
  })
}

export function addGroupAssignmentComment(data) {
  return request({
    method: 'post',
    url: '/student/routes/addGroupAssignmentComment',
    data: data
  })
}

export function fetchStudentWithoutTeams (data) {
  return request({
    method: 'post',
    url: '/course/routes/studentsWithoutTeams',
    data: data
  })
}

export function fetchStudentWithoutProjectTeams (data) {
  return request({
    method: 'post',
    url: '/course/routes/studentsWithoutProjectTeams',
    data: data
  })
}

export function addStudentToTeam (data) {
  return request({
    method: 'post',
    url: '/student/routes/addStudentToTeam',
    data: data
  })
}

export function addStudentToProjectTeam (data) {
  return request({
    method: 'post',
    url: '/student/routes/addStudentToProjectTeam',
    data: data
  })
}

export function deleteStudentFromProjectTeam (data) {
  return request({
    method: 'post',
    url: '/student/routes/deleteStudentFromProjectTeam',
    data: data
  })
}

export function downloadProjectData (data) {
  return request({
    method: 'post',
    url: '/student/routes/downloadProjectData',
    data: data
  })
}

export function deleteStudentFromTeam (data) {
  return request({
    method: 'post',
    url: '/student/routes/deleteStudentFromTeam',
    data: data
  })
}

export function saveGroupAssignmentParameter (data) {
  return request({
    method: 'post',
    url: '/course/routes/saveCapstoneProjectParameter',
    data: data
  })
}

export function saveGroupTeamScore (data) {
  return request({
    method: 'post',
    url: '/student/routes/addGroupAssignmentTeamScore',
    data: data
  })
}

export function downloadSparkPaper(){
  return request({
    method: 'post',
    url: '/student/routes/sparkPaper',
    responseType: 'blob',
  })
}

export function fetchMyCapstoneScore(data){
  return request({
    method: 'post',
    url: '/student/routes/fetchMyCapstoneScore',
    data: data
  })
}

export function saveGroupStudentPenalty(data){
  return request({
    method: 'post',
    url: '/course/routes/saveGroupStudentPenalty',
    data: data
  })
}

export function saveProjectHeader(data){
  return request({
    method: 'post',
    url: '/student/routes/createProjectHeader',
    data: data
  })
}

export function fetchProjectHeader(){
  return request({
    method: 'post',
    url: '/student/routes/fetchProjectHeader'
  })
}

export function saveProjectPhase(data){
  return request({
    method: 'post',
    url: '/student/routes/createProjectPhase',
    data: data
  })
}

export function fetchProjectPhase(data){
  return request({
    method: 'post',
    url: '/student/routes/fetchProjectPhase',
    data: data
  })
}

export function fetchProjectScheduleAdmin(data){
  return request({
    method: 'post',
    url: '/student/routes/fetchProjectScheduleAdmin',
    data: data
  })
}

export function approveProjectHeader(data){
  return request({
    method: 'post',
    url: '/student/routes/approveProject',
    data: data
  })
}

export function deleteProject(data){
  return request({
    method: 'post',
    url: '/student/routes/deleteProject',
    data: data
  })
}

export function getProjectPhaseNames(){
  return request({
    method: 'post',
    url: '/student/routes/getProjectPhaseNames'
  })
}

export function updateReviewParameters(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/updateReviewParameters'
  })
}

export function fetchProjectReviewParams(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/fetchProjectReviewParams'
  })
}

export function fetchReviewParameters(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/fetchReviewParameters'
  })
}

export function addProjectCriteriaScore(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/addProjectCriteriaScore'
  })
}

export function addProjectComment(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/addProjectComment'
  })
}

export function saveProjectTeamScore(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/saveProjectTeamScore'
  })
}

export function fetchProjectScoresAdmin(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/fetchProjectScoresAdmin'
  })
}

export function fetchProjectPeerComments(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/fetchProjectPeerComments'
  })
}

export function fetchStudentScoreProject(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/fetchStudentScoreProject'
  })
}

export function fetchCapstonePeerRating(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/fetchCapstonePeerRating'
  })
}

export function fetchProjectPeerRating(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/fetchProjectPeerRating'
  })
}

export function saveProjectPhaseDocument(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/saveProjectPhaseDocument'
  })
}

export function deleteProjectPhaseDocument(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/deleteProjectPhaseDocument'
  })
}

export function downloadProjectPhaseDocument(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/downloadProjectPhaseDocument',
    responseType: 'blob', // important
  })
}

export function fetchProjectPhaseDocument(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/fetchProjectPhaseDocument'
  })
}

export function fetchProjectMentorFeedbackAdmin(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/fetchProjectMentorFeedbackAdmin'
  })
}

export function saveProjectMentorFeedbackAdmin(data){
  return request({
    method: 'post',
    data: data,
    url: '/student/routes/saveProjectMentorFeedbackAdmin'
  })
}

export function fetchProjectMentorFeedbackStudent(){
  return request({
    method: 'post',
    url: '/student/routes/fetchProjectMentorFeedbackStudent'
  })
}
