import Vue from 'vue'
import VueRouter from 'vue-router'
import { ACCESS_TOKEN } from '../store/mutation-types'
import store from '../store'

Vue.use(VueRouter)

const adminRoutes = [
  {
    path: '/uploads',
    name: 'uploads',
    component: () => import('@/views/uploads/Uploads.vue'),
  },
  {
    path: '/teams',
    name: 'teams',
    component: () => import('@/views/teams/Card.vue'),
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/projects/Card.vue'),
  },
  {
    path: '/student-table',
    name: 'student-table',
    component: () => import('@/views/student-table/StudentTable.vue'),
  },
]

const allUserRoutes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/calendar/Calendar.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const studentRoutes = [
  {
    path: '/teams-eval',
    name: 'teams-eval',
    component: () => import('@/views/teams/Evaluate.vue'),
  },  
  {
    path: '/my-capstone-scores',
    name: 'my-capstone-scores',
    component: () => import('@/views/teams/MyScore.vue'),
  },
  {
    path: '/final-project-schedule',
    name: 'final-project-schedule',
    component: () => import('@/views/finalProject/Schedule.vue'),
  },
  {
    path: '/final-project-eval',
    name: 'final-project-eval',
    component: () => import('@/views/finalProject/Evaluate.vue'),
  },
  {
    path: '/my-project-scores',
    name: 'my-project-scores',
    component: () => import('@/views/projects/MyScore.vue'),
  },
]

const unAuthorizedRoutes = [
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/forgotPassword',
    name: 'pages-forgot',
    component: () => import('@/views/pages/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
    },
  },
]

const allRoutes = [...adminRoutes, ...studentRoutes ,...allUserRoutes, ...unAuthorizedRoutes]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: allRoutes,
})

router.beforeEach((to, from, next) => {
  let hasToken = localStorage.getItem(ACCESS_TOKEN) && localStorage.getItem(ACCESS_TOKEN).length > 6;
  let unauthorizedAccess = true;
  let needsToken = true;

  for(let h =0; h< studentRoutes.length; h++) {
    if(store.state.user && !store.state.user.isStaff && to.name === studentRoutes[h].name) {
      unauthorizedAccess = false;
      break;
    }
  }

  for(let i =0; i< adminRoutes.length; i++) {
    if(store.state.user && store.state.user.isStaff && to.name === adminRoutes[i].name) {
      unauthorizedAccess = false;
      break;
    }
  }
  
  /** Routes that are open for all users */
  for(let j=0; j < allUserRoutes.length; j++) {
    if(to.name === allUserRoutes[j].name){
      unauthorizedAccess = false;
      break;
    }
  }

  /** Routes that do not need any Auth */
  for(let k=0; k < unAuthorizedRoutes.length; k++) {
    if(to.name === unAuthorizedRoutes[k].name){
      needsToken = false;
      break;
    }
  }

  if(unauthorizedAccess && to.name !== 'error-404' && hasToken) {
    next({ name: 'error-404' })
  }
  else if (!hasToken && needsToken) {
    next({ name: 'pages-login' })
  }
  else{
    next()
  }

})

export default router
